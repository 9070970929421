import { pageGTMEvent } from '@lib/utils/thirdparty'

export function modalCollect(position, label) {
  pageGTMEvent({
    event: 'uaEvent',
    eventCategory: 'cookies_pop',
    eventAction: position,
    eventLabel: label, //按钮文案，右上角关闭传close
    nonInteraction: true,
  })

  pageGTMEvent({ event_parameters: null })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'lp_button',
    event_parameters: {
      page_group: 'cookies_pop',
      position: position,
      button_name: label, //按钮文案，右上角关闭传close
    },
  })
}

export function consentModalCollect(buttonText) {
  modalCollect('pop_1', buttonText)
}

export function settingsModalCollect(buttonText) {
  modalCollect('pop_2', buttonText)
}
