import 'vanilla-cookieconsent'
import { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import {
  consentModalCollect,
  modalCollect,
  settingsModalCollect,
} from './collect'
import { useRouter } from 'next/router'

const LOCALE_MAP = {
  'eu-de': 'de',
  uk: 'gbeng',
  'eu-en': 'eueng',
}

function CookieConsent({
  necessaryCookies,
  advertisingCookies,
  analyticsCookies,
  performanceCookies,
  cookieConsent,
  disableBeforeConsent,
}) {
  const [ready, setReady] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const { locale } = useRouter()

  const unNecessaryCookiesTable = useMemo(() => {
    return {
      advertising: advertisingCookies || [],
      analytics: analyticsCookies || [],
      performance: performanceCookies || [],
    }
  }, [advertisingCookies, analyticsCookies, performanceCookies])

  const firstAction = useRef(false)

  const clearCookie = useCallback(
    (categories = []) => {
      function _deleteCookie(cookieName, domain) {
        document.cookie = `${cookieName}=;Path=/;Expires=Thu, 01 Jan 1970 00:00:00 UTC;${
          domain ? `Domain=${domain};` : ''
        }`
        document.cookie = `${cookieName}=;Path=/;Secure=true;Expires=Thu, 01 Jan 1970 00:00:00 UTC;${
          domain ? `Domain=${domain};` : ''
        }`
        document.cookie = `${cookieName}=;Path=/;SameSite=Lax;Expires=Thu, 01 Jan 1970 00:00:00 UTC;${
          domain ? `Domain=${domain};` : ''
        }`
        document.cookie = `${cookieName}=;Path=/;Secure=true;SameSite=Lax;Expires=Thu, 01 Jan 1970 00:00:00 UTC;${
          domain ? `Domain=${domain};` : ''
        }`
      }

      function deleteCookieInAllDomain(cookieName) {
        _deleteCookie(cookieName)
        _deleteCookie(cookieName, `${window.location.host}`)
        _deleteCookie(cookieName, `.${window.location.host}`)
        _deleteCookie(
          cookieName,
          `.${window.location.host.split('.').slice(1).join('.')}`
        )
      }

      document.cookie.split(';').forEach(function (cookieStr) {
        const [cookieName] = cookieStr.split('=').map((item) => item.trim())

        if (
          necessaryCookies?.find((item) => {
            if (item.isRegex) {
              return new RegExp(item.col1).test(cookieName)
            } else {
              return item.col1 === cookieName
            }
          })
        ) {
          return
        }

        Object.entries(unNecessaryCookiesTable).forEach(
          ([category, cookies = []]) => {
            if (
              cookies?.find((item) => {
                if (item.isRegex) {
                  return new RegExp(item.col1).test(cookieName)
                } else {
                  return item.col1 === cookieName
                }
              })
            ) {
              if (!categories.includes(category)) {
                deleteCookieInAllDomain(cookieName)
              }
            }
          }
        )
      })
    },
    [necessaryCookies, unNecessaryCookiesTable]
  )

  const gtmTrigger = useCallback((categories) => {
    if (categories.includes('advertising')) {
      pageGTMEvent({
        event: 'cookie allow advertising',
      })
    } else {
      pageGTMEvent({
        event: 'cookie disallow advertising',
      })
    }

    if (categories.includes('performance')) {
      pageGTMEvent({
        event: 'cookie allow performance',
      })
    } else {
      pageGTMEvent({
        event: 'cookie disallow performance',
      })
    }

    if (categories.includes('analytics')) {
      pageGTMEvent({
        event: 'cookie allow analytics',
      })
    } else {
      pageGTMEvent({
        event: 'cookie disallow analytics',
      })
    }
  }, [])

  const eventTrigger = useCallback((cookieCategories) => {
    if (cookieCategories.includes('advertising')) {
      const allowEvent = new Event('cookie allow advertising')
      window.dispatchEvent(allowEvent)
    } else {
      const disallowEvent = new Event('cookie disallow advertising')
      window.dispatchEvent(disallowEvent)
    }

    if (cookieCategories.includes('performance')) {
      const allowEvent = new Event('cookie allow performance')
      window.dispatchEvent(allowEvent)
    } else {
      const disallowEvent = new Event('cookie disallow performance')
      window.dispatchEvent(disallowEvent)
    }

    if (cookieCategories.includes('analytics')) {
      const allowEvent = new Event('cookie allow analytics')
      window.dispatchEvent(allowEvent)
    } else {
      const disallowEvent = new Event('cookie disallow analytics')
      window.dispatchEvent(disallowEvent)
    }
  }, [])

  const handleFirstAction = useCallback(
    (categories) => {
      window.consentedCookie = true
      window.cookieCategories = categories
      gtmTrigger(categories)
    },
    [gtmTrigger]
  )

  const handleAccept = useCallback(
    (config) => {
      window.consentedCookie = true
      window.cookieCategories = config.categories

      if (
        config.categories.length === 1 &&
        config.categories[0] === 'necessary'
      ) {
        window.disableCookies = true
      } else {
        window.disableCookies = false
      }

      clearCookie(config.categories)

      let CMP = {
        s: '',
        m: '0',
        a: '0',
        p: '0',
      }

      if (config.categories.includes('analytics')) {
        CMP.a = '1'
      }
      if (config.categories.includes('advertising')) {
        CMP.m = '1'
      }
      if (config.categories.includes('performance')) {
        CMP.p = '1'
      }

      if (window.gtag) {
        gtag('consent', 'update', {
          ad_storage: CMP.m == '1' ? 'granted' : 'denied',
          analytics_storage: CMP.a == '1' ? 'granted' : 'denied',
          ad_personalization: CMP.m == '1' ? 'granted' : 'denied',
          ad_user_data: CMP.m == '1' ? 'granted' : 'denied',
        })
      }

      document.cookie = `_tracking_consent=${encodeURIComponent(
        JSON.stringify({
          v: '2.1',
          region: (LOCALE_MAP[locale] || 'us').toUpperCase(),
          reg: 'GDPR',
          con: { CMP },
          lim: ['GDPR_BLOCK_ALL'],
        })
      )}; expires=${new Date(
        new Date() * 1 + 365 * 864e5
      ).toUTCString()}; sameSite=Lax; domain=.anker.com; path=/`

      eventTrigger(config.categories)
      window?.CookieConsentApi?.updateScripts()
    },
    [eventTrigger, clearCookie, locale]
  )

  const handleChange = useCallback(
    (config) => {
      handleAccept(config)
      if (!firstAction.current) {
        gtmTrigger(config.categories)
      } else {
        firstAction.current = false
      }
    },
    [handleAccept, gtmTrigger]
  )

  const start = useCallback(() => {
    setLoaded(true)

    if (!document.getElementById('cc--main') && cookieConsent) {
      if (disableBeforeConsent !== 'true' && disableBeforeConsent !== true) {
        document.body.className += ' not-gdpr'
      }

      window.CookieConsentApi = window.initCookieConsent()
      const cookieConsentConfig = {
        autoclear_cookies: true, // default: false
        page_scripts: true, // default: false
        mode: 'opt-in', // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        onAccept: (cookieConsentConfig) => {
          handleAccept(cookieConsentConfig)
        },
        onChange: (cookieConsentConfig) => {
          handleChange(cookieConsentConfig)
        },
        onFirstAction: ({ accepted_categories }) => {
          firstAction.current = true
          handleFirstAction(accepted_categories)
        },
        ...cookieConsent,
      }
      window.CookieConsentApi.run(cookieConsentConfig)

      if (
        (disableBeforeConsent === 'false' || !disableBeforeConsent) &&
        !navigator.globalPrivacyControl
      ) {
        // 点击空白区关闭
        function hasParentById(el, parentId) {
          if (el.id === parentId) {
            return true
          }

          if (el.parentElement) {
            return hasParentById(el.parentElement, parentId)
          }
        }

        function handleBodyClick(event) {
          if (window.CookieConsentApi) {
            if (hasParentById(event.target, 'cc_div')) {
              return
            }
            window.CookieConsentApi.hide()
          }
          document.body.removeEventListener('click', handleBodyClick)
        }

        document.body.addEventListener('click', handleBodyClick, {
          once: true,
        })

        // 为consent弹窗增加close按钮
        if (document.getElementById('cm')) {
          const closeButton = document.createElement('div')
          closeButton.innerHTML = `<div id="consent-close" class="consent-close"></div>`

          document.getElementById('cm').appendChild(closeButton)

          document
            .getElementById('consent-close')
            .addEventListener('click', function () {
              consentModalCollect('close')
              window.CookieConsentApi.accept('all')
              window.CookieConsentApi.hide()
            })
        }
      }

      if (navigator.globalPrivacyControl) {
        try {
          if (typeof document !== 'undefined') {
            window.CookieConsentApi.accept([])
          }
        } catch (e) {
          console.error('cookie consent accept error')
        }
        handleAccept({ categories: ['necessary'] })
      }

      // 埋点添加
      if (!window.consentedCookie) {
        modalCollect('pop_1', 'impression')
      }

      // Cookie Settings
      document
        .getElementById('c-s-bn')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      // Accept All
      document
        .getElementById('c-p-bn')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      document
        .getElementById('cookie-notice')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      document
        .getElementById('cookie-preferences')
        ?.addEventListener('click', function (event) {
          consentModalCollect(event.target.innerText)
        })
      document
        .querySelector('[data-cc="c-settings"]:not(#cookie-preferences)')
        ?.addEventListener('click', function (event) {
          modalCollect('entry_click', event.target.innerText)
        })

      document
        .getElementById('s-c-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect('close')
        })
      document
        .getElementById('s-sv-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
      document
        .getElementById('s-all-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
      document
        .getElementById('s-rall-bn')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
      document
        .getElementById('contact')
        ?.addEventListener('click', function (event) {
          settingsModalCollect(event.target.innerText)
        })
    }
  }, [
    disableBeforeConsent,
    cookieConsent,
    handleChange,
    handleFirstAction,
    handleAccept,
  ])

  useEffect(() => {
    window.cookieCategories = []
    let ccCookieValue
    document.cookie.split(';').forEach((item) => {
      const [cookieName, cookieValue] = item.trim().split('=')
      if (cookieName === 'cc_cookie') {
        ccCookieValue = JSON.parse(cookieValue)
      }
    })
    if (ccCookieValue) {
      window.cookieCategories = ccCookieValue.categories
      window.consentedCookie = true
    }
  }, [])

  useEffect(() => {
    if (ready && !loaded) {
      start()
    }
  }, [ready, loaded, start])

  useEffect(() => {
    // webpagetest 测试忽略
    if (window.navigator.userAgent.includes('PTST')) {
      return
    }

    let timer
    // 尽量在ga pageview 事件后加载
    timer = setInterval(() => {
      if (window.google_tag_manager) {
        setReady(true)
        clearInterval(timer)
      }
    }, 500)
  }, [])

  return null
}

export default CookieConsent
